<template>
    <v-form v-model="personal.validation.valid" ref="personalInfoForm" lazy-validation>
        <v-timeline
                class="user_add_form"
                align-top
                dense>
            <v-timeline-item
                    color="red"
                    small>
                <v-layout pt-3>
                    <v-flex>
                        <strong>Personal Information</strong>
                        <div class="caption">Select type of staff.</div>
                        <v-flex xs11>
                            <v-flex pt-3 xs12>
                                <v-container style="padding:0" grid-list-md>
                                    <v-layout row wrap>
                                        <v-flex sm3 xs12>
                                            <!--<small class="input-label">First Name.</small>-->
                                            <v-text-field outlined dense
                                                          autocomplete="off"
                                                          label="First Name*" required class="pa-0"
                                                          v-model="updateUserForm.fname"
                                                          name="name"
                                                          :rules="personal.validation.fnameRule"
                                            />
                                            <!--<h4>{{user.fname || '-'}}</h4>-->
                                        </v-flex>
                                        <v-flex sm3 xs12>
                                            <!--<small class="input-label">Middle Name.</small>-->
                                            <v-text-field outlined dense
                                                          autocomplete="off"
                                                          label="Middle Name" required class="pa-0"
                                                          v-model="updateUserForm.mname"
                                                          name="name"
                                                          :rules="personal.validation.fnameRule"/>
                                        </v-flex>

                                        <v-flex sm3 xs12>
                                            <!--<small class="input-label">Last Name.</small>-->
                                            <v-text-field outlined dense
                                                          autocomplete="off"
                                                          label="Last Name*" required class="pa-0"
                                                          v-model="updateUserForm.lname"
                                                          name="name"
                                                          :rules="personal.validation.fnameRule"/>
                                        </v-flex>

                                        <v-flex sm3 xs12>
                                            <!--<small class="input-label">Gender.</small>-->
                                            <v-autocomplete
                                                    outlined dense
                                                    v-model="updateUserForm.gender"
                                                    prepend-inner-icon="face"
                                                    :items="gender"
                                                    :rules="personal.validation.genderRule"
                                                    label="Gender"
                                                    data-vv-name="select"
                                                    required/>
                                        </v-flex>
                                        <v-flex sm3 xs12>
                                            <v-autocomplete
                                                    outlined dense
                                                    v-model="updateUserForm.blood_group"
                                                    prepend-inner-icon="add_location"
                                                    :items="blood"
                                                    label="Blood"
                                                    data-vv-name="select"
                                                    required/>
                                        </v-flex>

                                        <v-flex sm3 xs12>
                                            <v-calendar-field
                                                    id-val="dob"
                                                    prepend-icon="cake"
                                                    v-model="updateUserForm.dob"
                                                    label="Date of birth">
                                            </v-calendar-field>
                                        </v-flex>

                                        <v-flex sm3 xs12>
                                            <!--<small class="input-label">Religion.</small>-->
                                            <v-autocomplete
                                                    outlined dense
                                                    v-model="updateUserForm.religion"
                                                    prepend-inner-icon="account_balance"
                                                    :items="religions"
                                                    label="Religion"
                                                    data-vv-name="select"
                                                    required/>
                                        </v-flex>

                                        <v-flex sm3 xs12>
                                            <v-autocomplete
                                                    outlined dense
                                                    prepend-inner-icon="place"
                                                    v-model="updateUserForm.ethnicity"
                                                    :items="ethnicities"
                                                    label="Ethnicity"
                                                    data-vv-name="select"
                                                    required/>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-flex>
                        </v-flex>
                    </v-flex>
                </v-layout>
            </v-timeline-item>
            <v-timeline-item
                    color="purple lighten-1"
                    small>
                <v-layout pt-3>
                    <v-flex>
                        <strong>Address Information</strong>
                        <div class="caption">Primary/Temporary</div>
                        <v-flex pt-3 xs11>
                            <v-container style="padding:0" grid-list-md>
                                <h3 class="mb-2">Permanent</h3>
                                <v-layout row wrap>
                                    <v-flex xs12 sm3>
                                        <v-autocomplete
                                                outlined dense

                                                :items="states"
                                                v-model="updateUserForm.pstate_id"
                                                label="Province/State"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.permanent_state_id_rule"
                                                required/>
                                    </v-flex>
                                    <v-flex xs12 sm2>
                                        <v-autocomplete
                                                outlined dense
                                                :items="permanent_districts"
                                                v-model="updateUserForm.pdistrict_id"
                                                label="District"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.permanent_district_id_rule"
                                                required
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                        <v-autocomplete
                                                outlined dense

                                                :items="permanent_cities"
                                                v-model="updateUserForm.pcity_id"
                                                label="Municipality/City"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.permanent_city_id_rule"
                                                required
                                        />
                                    </v-flex>

                                    <v-flex xs12 sm1>
                                        <v-autocomplete
                                                outlined dense

                                                :items="ward_count_permanent"
                                                :rules="form.addressInfo.validation.permanent_ward_no"
                                                v-model="updateUserForm.pward_no"
                                                label="Ward"
                                                data-vv-name="select"
                                                required
                                        />
                                    </v-flex>

                                    <v-flex xs12 sm3>
                                        <v-text-field
                                                outlined dense
                                                label="Street/Tole"
                                                      v-model="updateUserForm.pstreet"
                                                      :rules="form.addressInfo.validation.permanent_address_line_rule"/>
                                    </v-flex>
                                </v-layout>
                                <v-flex xs12>
                                    <v-checkbox
                                            label="Mark current address same as permanent"
                                            v-model="same_address"
                                            :disabled="!updateUserForm.pstreet || !updateUserForm.pcity_id"
                                    />
                                </v-flex>
                                <br>
                                <h3 class="mb-2">Temporary / Current</h3>
                                <v-layout row wrap>
                                    <v-flex xs12 sm3>
                                        <v-autocomplete
                                                outlined dense
                                                :items="states"
                                                v-model="updateUserForm.tstate_id"
                                                label="Province/State"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.temporary_state_id_rule"
                                                required
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm2>
                                        <v-autocomplete
                                                outlined dense
                                                :items="temporary_districts"
                                                v-model="updateUserForm.tdistrict_id"
                                                label="District"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.temporary_district_id_rule"
                                                required
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm3>
                                        <v-autocomplete
                                                outlined dense
                                                :items="temporary_cities"
                                                v-model="updateUserForm.tcity_id"
                                                label="Municipality/City"
                                                data-vv-name="select"
                                                :rules="form.addressInfo.validation.temporary_city_id_rule"
                                                required
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm1>
                                        <v-autocomplete
                                                outlined dense
                                                :items="ward_count_temporary"
                                                :rules="form.addressInfo.validation.temporary_ward_no"
                                                v-model="updateUserForm.tward_no"
                                                label="Ward"
                                                data-vv-name="select"
                                                required
                                        />
                                    </v-flex>

                                    <v-flex xs12 sm3>
                                        <v-text-field
                                                outlined dense
                                                label="Street/Tole"
                                                      v-model="updateUserForm.tstreet"
                                                      :rules="form.addressInfo.validation.temporary_address_line_rule"/>

                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-flex>
                    </v-flex>
                </v-layout>
            </v-timeline-item>

            <v-timeline-item
                    color="green"
                    small>
                <v-layout pt-3 pb-5>
                    <v-flex>
                        <strong>Contact information</strong>
                        <div class="caption">Religion/Ethnicity</div>
                        <v-flex pt-3 xs11>
                            <v-container style="padding:0" grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs4>
                                        <v-text-field
                                                prepend-inner-icon="email"
                                                outlined dense
                                                autocomplete="off" label="Email"
                                                required
                                                class="pa-0"
                                                :rules="personal.validation.emailRule"
                                                v-model="updateUserForm.email" name="rank"
                                        />
                                        <span class="private-font">

                                            <v-checkbox color="blue" style="margin-top:-25px;"
                                                    v-model="updateUserForm.email_private"
                                                    label="Make Private"/>
                                        </span>


                                        <!--<h4>{{updateUserForm.email||'-'}}</h4>-->
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-text-field
                                                prepend-inner-icon="mobile_screen_share"
                                                outlined dense
                                                autocomplete="off" label="Email"
                                                required
                                                class="pa-0"
                                                :rules="personal.validation.emailRule"
                                                v-model="updateUserForm.primary_mobile" name="rank"
                                        />
                                        <span class="private-font">
                                            <v-checkbox color="blue" style="margin-top:-25px;"
                                                    v-model="updateUserForm.mobile_private"
                                                    label="Make Private"/>
                                        </span>


                                        <!--<h4>{{updateUserForm.primary_mobile||'-'}}</h4>-->
                                    </v-flex>

                                    <v-flex xs4>
                                        <v-text-field
                                                mask="##########"
                                                outlined dense
                                                v-model="updateUserForm.residence"
                                                label="Phone"
                                                prepend-inner-icon="perm_phone_msg"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-flex>
                    </v-flex>
                </v-layout>

                <v-card-actions style="padding-bottom: 80px;">
                    <v-btn @click="$emit('onCancel')" color="warning" outlined>
                        <v-icon dark left>arrow_back</v-icon>
                        Cancel
                    </v-btn>

                    <v-btn color="success" outlined @click="updateMyProfile">Update General Info</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

            </v-timeline-item>

        </v-timeline>


    </v-form>
</template>

<script>
    import statesData from '@/assets/json/states.json';
    import districtDataData from '@/assets/json/districts.json';
    import cityData from '@/assets/json/cities.json';
    import Form from "../../../../../library/Form";

    export default {
        props: {
            user: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                userUpdate: {},
                updateUserForm: new Form({
                    blood_group: '',
                    dob: '',
                    email: '',
                    email_private: false,
                    mobile_private: false,
                    ethnicity: '',
                    fname: '',
                    gender: '',
                    lname: '',
                    mname: '',
                    occupation: '',
                    pcity_id: '',
                    pdistrict_id: '',
                    primary_mobile: '',
                    pstate_id: '',
                    pstreet: '',
                    pward_no: '',
                    religion: '',
                    residence: '',
                    secondary_mobile: '',
                    tcity_id: '',
                    tdistrict_id: '',
                    tstate_id: '',
                    tstreet: '',
                    tward_no: ''
                }, '/auth/me/update'),
                form: {
                    addressInfo: {
                        data: {},
                        validation: {
                            valid: false,
                            permanent_state_id_rule: [
                                (v) => !!v || 'Please select state!'
                            ],
                            temporary_state_id_rule: [
                                (v) => !!v || 'Please select state!'
                            ],
                            permanent_address_line_rule: [
                                (v) => !!v || 'Address is required!'
                            ],
                            temporary_address_line_rule: [
                                (v) => !!v || 'Address is required!'
                            ],
                            permanent_district_id_rule: [
                                (v) => !!v || 'Please select district!'
                            ],
                            temporary_district_id_rule: [
                                (v) => !!v || 'Please select district!'
                            ],
                            permanent_city_id_rule: [
                                (v) => !!v || 'Please select municipality/city!'
                            ],
                            temporary_city_id_rule: [
                                (v) => !!v || 'Please select municipality/city!'
                            ],
                        }
                    },
                    error: null
                },
                personal: {
                    validation: {
                        valid: true
                    }
                },
                gender: ['Male', 'Female', 'Others'],
                blood: [
                    'AB+',
                    'AB-',
                    'A+',
                    'A-',
                    'B+',
                    'B-',
                    'O+',
                    'O-'
                ],
                religions: ['Hindu', 'Muslim', 'Buddhist', 'Christian', 'Other'],
                ethnicities: ['Brahmin', 'Chhetri', 'Janajati', 'Dalit', 'Muslim', 'Newari', 'Madhesi', 'Other'],
                states: [],
                permanent_districts: [],
                temporary_districts: [],
                permanent_cities: [],
                temporary_cities: [],
                ward_count_permanent: [],
                ward_count_temporary: [],
                same_address: false,
                statesData,
                districtDataData,
                cityData,

            }
        },
        watch: {
            'updateUserForm.pstate_id': {
                handler(v) {
                    this.permanent_districts = [];
                    this.permanent_cities = [];
                    this.ward_count_permanent = [];
                    this.getDistricts('permanent', v)
                }
            },
            'updateUserForm.pdistrict_id': {
                handler(v) {
                    this.ward_count_permanent = [];
                    this.permanent_cities = [];
                    this.getCities('permament');
                }
            },
            'updateUserForm.pcity_id': {
                handler(v) {
                    this.ward_count_permanent = [];
                    this.getWardCount('permanent');
                }
            },
            'updateUserForm.pstreet': {
                handler(name) {
                    this.updateUserForm.pstreet = this.capitalizeString(name)
                }
            },
            'updateUserForm.tstreet': {
                handler(name) {
                    this.updateUserForm.tstreet = this.capitalizeString(name)
                }
            },
            'updateUserForm.tstate_id': {
                handler(v) {
                    this.temporary_districts = [];
                    this.temporary_cities = [];
                    this.ward_count_temporary = [];
                    this.getTemporaryDistricts('temporary', v)
                }
            },
            'updateUserForm.tdistrict_id': {
                handler(v) {
                    this.ward_count_temporary = [];
                    this.temporary_cities = [];
                    this.getTemporaryCities('temporary', v)
                }
            },
            'updateUserForm.tcity_id': {
                handler() {
                    this.ward_count_temporary = [];
                    this.getWardCount('temporary');
                }
            },
            'updateUserForm.fname': {
                handler(name) {
                    this.updateUserForm.fname = this.capitalizeString(name)
                }
            },

            'updateUserForm.mname': {
                handler(name) {
                    this.updateUserForm.mname = this.capitalizeString(name)
                }
            },

            'updateUserForm.lname': {
                handler(name) {
                    this.updateUserForm.lname = this.capitalizeString(name)
                }
            },
            // 'student.addressData': function () {
            //     if (Object.keys(this.student.addressData).length > 0) {
            //         this.getDistricts();
            //         this.getTemporaryCities();
            //         this.getTemporaryDistricts();
            //         this.getCities();
            //         this.getWardCount('temporary');
            //         this.getWardCount('permanent');
            //     }
            // },
            'same_address': {
                handler(v) {
                    this.copyAddress(v);
                }
            }
        },
        mounted() {
            this.userUpdate = this.user;
            this.updateUserForm.edit(this.user);
            this.getStates();
        },
        methods: {
            getStates(type) {
                this.states = this.statesData.map(item => {
                    return {text: item.name, value: item.id}
                });
            },
            getDistricts(type, id) {
                this.permanent_districts = this.districtDataData.filter(item => item.state_id === this.updateUserForm.pstate_id).map(item => {
                    return {value: item.id, text: item.name}
                });
            },
            getTemporaryDistricts() {
                this.temporary_districts = this.districtDataData.filter(item => item.state_id === this.updateUserForm.tstate_id).map(item => {
                    return {value: item.id, text: item.name}
                });
            },

            getCities() {
                this.permanent_cities = this.cityData.filter(item => item.district_id === this.updateUserForm.pdistrict_id).map(item => {
                    return {value: item.id, text: item.name}
                });
            },

            getTemporaryCities() {
                this.temporary_cities = this.cityData.filter(item => item.district_id === this.updateUserForm.tdistrict_id).map(item => {
                    return {value: item.id, text: item.name}
                });
            },
            copyAddress(status) {
                if (status) {
                    if (this.updateUserForm.pstreet && this.updateUserForm.pcity_id) {
                        let temp = {
                            state: this.updateUserForm.pstate_id,
                            district: this.updateUserForm.pdistrict_id,
                            city: this.updateUserForm.pcity_id,
                            address: this.updateUserForm.pstreet,
                            ward_no: this.updateUserForm.pward_no
                        }
                        this.updateUserForm.tstate_id = temp.state;
                        this.updateUserForm.tdistrict_id = temp.district;
                        this.updateUserForm.tcity_id = temp.city;
                        this.updateUserForm.tward_no = temp.ward_no;
                        this.updateUserForm.tstreet = temp.address;
                    }
                }
            },
            getWardCount(type) {
                let $this = this;
                let totalWards = [];
                if (type === 'permanent') {
                    let city = this.cityData.filter(function (city, key) {
                        return $this.updateUserForm.pcity_id === city.id;
                    });
                    this.ward_count_permanent = [];
                    if (city.length) {
                        city = city[0];
                        let totalNumberOfWards = parseInt(city.ward_count);
                        for (let i = 1; i <= totalNumberOfWards; i++) {
                            totalWards.push(i);
                        }
                    }
                    this.ward_count_permanent = totalWards;
                } else {
                    let city = this.cityData.filter(function (city, key) {
                        return $this.updateUserForm.tcity_id === city.id;
                    });
                    this.ward_count_temporary = [];
                    if (city.length) {
                        city = city[0];
                        let totalNumberOfWards = parseInt(city.ward_count);
                        for (let i = 1; i <= totalNumberOfWards; i++) {
                            totalWards.push(i);
                        }
                    }
                    this.ward_count_temporary = totalWards;
                }
            },
            capitalizeString(string) {
                if (string === undefined || string === null) return '';
                return string.charAt(0).toUpperCase() + string.slice(1)
            },
            updateMyProfile() {
                // console.log(this.userUpdate);
                this.updateUserForm.fireFetch = false;
                this.updateUserForm.endpoint = this.updateUserForm.endpoint;
                // console.log(this.updateUserForm.data());
                this.updateUserForm.store().then(res => {
                    this.$emit('onUpdate')
                });

            }
        }
    }
</script>
<style lang="scss">
    /*span.private-font .v-input--selection-controls.v-input .v-label {*/
    /*    font-size: 12px;*/
    /*}*/
</style>